import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="active-link"
export default class extends Controller {
  static targets = ["link", "frame"];
  static values = {
    path: { default: "", type: String },
  };
  static classes = ["css"];

  connect() {}

  setActivePath(event) {
    const el = event.target;
    this.pathValue = el.href;
  }

  pathValueChanged(current, old) {
    this.linkTargets.forEach((linkTarget) => {
      const activeEl = linkTarget.closest("li");
      if (linkTarget.href === current) {
        activeEl.classList.add(this.cssClass);
      } else {
        activeEl.classList.remove(this.cssClass);
      }
    });
  }
}
